<template>
  <div class="field-text">
    <label class="field-text__form-label">
      <span class="field-text__label mb-1">{{ labelText }}</span>
      <input
          class="field-text__form-input"
          :type="type"
          :value="value"
          @input="onInput"
          :class="{
            'form-input-error': validation,
            'field-text__form-input--full-width': fullWidth,
            'field-text__form-input--icon': icon,
          }"
          :tabindex="tabindex"
      >
      <span class="field-text__form-icon" v-if="icon">
        <v-icon :icon="iconName" class="field-text__icon" />
      </span>
    </label>
  </div>
</template>

<script>
export default {
  name: "FieldText",
  props: {
    type: {
      type: String,
      default: 'text',
    },
    labelText: String,
    value: String,
    validation: {
      type: Boolean,
      default: false
    },
    icon: {
      type: Boolean,
      default: false
    },
    iconName: String,
    tabindex: Number,
    fullWidth: Boolean,
  },
  methods: {
    onInput(e) {
      this.$emit('input', e.target.value);
    },
  }
};
</script>

<style lang="scss" scoped>
.field-text {
  &__form-label {
    display: flex;
    flex-direction: column;
    width: 100%;
    position: relative;
  }

  &__form-input {
    display: flex;
    flex-direction: column;
    width: 100%;
    min-height: 40px;
    max-width: 360px;
    border: 1px solid var(--grey-light-1);
    border-radius: 8px;
    padding: 0 15px;
    position: relative;

    &:focus {
      outline: none;
      border: 1px solid var(--blue-main);
    }

    &--full-width {
      max-width: 100%;
    }

    &--icon {
      padding: 0 48px 0 15px;
    }
  }

  &__icon {
    position: absolute;
    top: 36px;
    right: 40px;
    cursor: pointer;
  }

  .form-input-error {
    border: 1px solid var(--red-main);
  }

  .text-error {
    color: var(--red-main);
  }
}
</style>