<template>
  <div class="offers-tracking">
    <h2 class="offers-tracking__title title-page">
      <router-link to="/offers" class="offers-tracking__link">
        Offers
      </router-link>
      <icon-arrow color="#1E70BB"/>
      Tracking
    </h2>
    <div class="offers-tracking__main">
      <h2 class="offers-tracking__main-title mb-2">Fields</h2>
      <form class="offers-tracking__form">
        <div class="offers-tracking__form-row">
          <div class="offers-tracking__form-col">
            <FieldText
                v-model="form.linkParams.sourceId"
                labelText="Source ID"
                :icon="true"
                iconName="id-card"
                class="mb-3"
            />

            <FieldText
                v-model="form.linkParams.sub1"
                labelText="Sub 1"
                class="mb-3"
            />

            <FieldText
                v-model="form.linkParams.sub2"
                labelText="Sub 2"
                class="mb-3"
            />
          </div>
          <div class="offers-tracking__form-col">
            <FieldText
                v-model="form.linkParams.sub3"
                labelText="Sub 3"
                class="mb-3"
            />

            <FieldText
                v-model="form.linkParams.sub4"
                labelText="Sub 4"
                class="mb-3"
            />

            <FieldText
                v-model="form.linkParams.sub5"
                labelText="Sub 5"
                class="mb-3"
            />
          </div>
        </div>

        <div class="offers-tracking__form-row mb-2">
          <SwitchField
              class="mr-1"
              @change="form.isGenerateAllOffers = !form.isGenerateAllOffers"
              :checked="form.isGenerateAllOffers"
          />
          Generate for All offers
        </div>

        <div v-if="!form.isGenerateAllOffers && _defaultOffers.length">
          <TableColumns
              :defaultColumns="_defaultOffers"
              @save="form.selectedOffersIds = $event"
              subtitle="Available (Offers)*"
              colNameLeft="Available for selection"
              colNameRight="Selected offers"
              :disabledBtns="true"
              ref="tableColumns"
              searchBy="columnsDefault"
              :validation="errorValid"
          />
        </div>
        <div class="offers-tracking__form-btns">
          <v-button
              type="orange-outline"
              class="btn-ml mr-2"
              @click="$router.push('/offers')">
            Cancel
          </v-button>
          <v-button
              type="orange"
              class="btn-ml"
              @click="submitForm"
          >
            Generate Traffic Links
          </v-button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import IconArrow from "@/js/components/icons/IconArrow";
import FieldText from "@/js/components/common/form/FieldText";
import SwitchField from "@/js/components/common/form/SwitchField";
import TableColumns from "@/js/components/pages/offers/popup/TableColumns";

export default {
  name: "OffersTracking",
  components: {TableColumns, SwitchField, FieldText, IconArrow},
  data() {
    return {
      form: {
        linkParams: {
          sourceId: '',
          sub1: '',
          sub2: '',
          sub3: '',
          sub4: '',
          sub5: '',
        },
        selectedOffersIds: [],
        isGenerateAllOffers: false,
      },
      errorValid: false
    }
  },
  computed: {
    _defaultOffers() {
      return this.$store.state.trackingList.offersRunnable || []
    },
  },
  mounted() {
    this.getOffersRunnable()
  },

  methods: {
    async getOffersRunnable() {
      try {
        await this.$store.dispatch('trackingList/getOffersRunnable')
      } catch (err) {
        console.log('=> ERR', err)
      }
    },

    submitForm() {
      console.log('Form', this.form)
      if (!this.form.isGenerateAllOffers) this.$refs.tableColumns.save();
      if (this.form.selectedOffersIds.length === 0 && !this.form.isGenerateAllOffers) {
        this.errorValid = true
        return
      }
      this.$store.commit('trackingList/SET_FORM_PARAMS_OFFER', this.form)
      this.$router.push('/offers/tracking/list')
    },
  }
}
</script>

<style lang="scss" scoped>
.offers-tracking {
  &__title {
    display: flex;
    align-items: center;
  }

  &__link {
    text-decoration: none;
    color: var(--blue-main);
  }

  &__main {
    width: 100%;
    max-width: 800px;
    padding: 20px;
    background: #FFFFFF;
    box-shadow: var(--main-box-shadow);
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }

  &__form {
    width: 100%;
  }

  &__form-row {
    width: 100%;
    display: flex;
    transition: .2s ease;
    position: relative;
  }

  &__form-col {
    width: 50%;
  }

  &__form-btns {
    display: flex;
    justify-content: flex-end;
  }
}

</style>