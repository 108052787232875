<template>
  <label class="switch-field">
    <input type="checkbox" :checked="checked" @change="$emit('change', $event)">
    <span class="slider"></span>
  </label>
</template>

<script>
export default {
  name: "SwitchField",
  props: {
    checked: Boolean,
  }
}
</script>

<style scoped>

.switch-field {
  position: relative;
  display: inline-block;
  width: 37px;
  height: 20px;
}

.switch-field input {
  display: none;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
  border-radius: 34px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 14px;
  width: 14px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
  border-radius: 50%;
}

input:checked + .slider {
  background-color: #27AE60;
}

input:focus + .slider {
  box-shadow: 0 0 1px #27AE60;
}

input:checked + .slider:before {
  -webkit-transform: translateX(14px);
  -ms-transform: translateX(14px);
  transform: translateX(14px);
}

</style>