<template>
  <svg width="24" height="24" viewBox="0 0 24 24" :fill="color" xmlns="http://www.w3.org/2000/svg">
    <path
        d="M15.7638 13.1525C15.749 13.2007 15.7389 13.2513 15.7195 13.2979C15.6666 13.4247 15.6363 13.4465 15.5507 13.5507L9.32472 19.7759C9.24617 19.8405 9.16606 19.9058 9.07273 19.9447C8.74296 20.0816 8.32685 19.9563 8.12775 19.6585C7.95742 19.4034 7.95742 19.0487 8.12775 18.7936C8.15575 18.7508 8.1923 18.715 8.22419 18.6754L13.8995 13L8.22419 7.3255L8.12775 7.20728C8.10364 7.16217 8.07564 7.11939 8.05619 7.07273C7.91931 6.74373 8.04064 6.32997 8.34241 6.12775C8.59751 5.95742 8.95217 5.95742 9.20728 6.12775C9.24928 6.15652 9.28583 6.1923 9.32472 6.22497L15.5507 12.4502C15.5826 12.4898 15.6191 12.5256 15.6471 12.5684C15.7039 12.6532 15.7436 12.748 15.7638 12.8484C15.7832 12.9479 15.7731 13.0506 15.7638 13.1525Z"
        :fill="color"/>
  </svg>
</template>

<script>
export default {
  name: "IconArrow",
  props: {
    color: {
      type: String,
      default: "#333333"
    }
  }
}
</script>